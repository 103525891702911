import React, {useEffect} from "react";
import {createUseStyles} from "react-jss";
import {initialize} from "../App/service";
import {useThreeSixtyProps,} from "../App/ThreeSixtyContext";
import {ThreeSixty} from "../Canvas/ThreeSixty";
import {useAddAdditionalDimensions} from "./useAddAdditionalDimensions";
import {NoDomesPlaceholder} from "./NoDomesPlaceholder";
import {PlaceHolderContainer} from "./PlaceHolderContainer";

const useStyles = createUseStyles({
    admin: {
        display: "flex",
        overflow: "hidden",
        height: "100%",
        width: "100%",
        fontFamily:
            '"Akkurat", -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
    },
    content: {
        display: "flex",
        height: "100%",
    },
    "@font-face": [
        {
            fontFamily: "Akkurat",
            src: 'url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Regular.woff2") format("woff2"), url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Regular.woff") format("woff")',
            fontWeight: "400",
            fontStyle: "normal",
            fontDisplay: "swap",
        },
        {
            fontFamily: "Akkurat",
            src: 'url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Bold.woff2") format("woff2"), url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Bold.woff") format("woff")',
            fontWeight: "700",
            fontStyle: "normal",
            fontDisplay: "swap",
        },
        {
            fontFamily: "Akkurat",
            src: 'url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-BoldItalic.woff2") format("woff2"), url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-BoldItalic.woff") format("woff")',
            fontWeight: "700",
            fontStyle: "italic",
            fontDisplay: "swap",
        },
        {
            fontFamily: "Akkurat",
            src: 'url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Italic.woff2") format("woff2"), url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Italic.woff") format("woff")',
            fontWeight: "400",
            fontStyle: "italic",
            fontDisplay: "swap",
        },
        {
            fontFamily: "Akkurat Mono",
            src: 'url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratMonoLLWeb-Regular.woff2") format("woff2"), url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratMonoLLWeb-Regular.woff") format("woff")',
            fontWeight: "400",
            fontStyle: "normal",
            fontDisplay: "swap",
        },
    ],
})

export const Admin = () => {
    const {onChange, data} = useThreeSixtyProps()
    useAddAdditionalDimensions(data?.domes ? data?.domes.map(d => d.image) : [])
    const classes = useStyles();

    useEffect(() => {
        if (
            data === undefined ||
            Object.keys(data).length === 0 ||
            data.domes === undefined
        ) {
            onChange(initialize(data));
        }
    }, [data, onChange]);

    return (
        <div className={classes.admin}>
            {data?.domes?.length > 0 ? (
                <ThreeSixty data={data}/>
            ) : (
                <PlaceHolderContainer>
                    <NoDomesPlaceholder />
                </PlaceHolderContainer>
            )}

        </div>
    );
};
